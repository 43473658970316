import React from 'react';

import Layout from '../../components/Layout';
import Feature from '../../components/Feature';
import theme from '../../theme';
import IntraIcon from '../../images/intra.jpg';
import B1 from '../../images/new/intra_fct1.jpg';
import B2 from '../../images/new/intra_fct2.jpg';
import B3 from '../../images/new/intra_fct3.jpg';

const IntraPage = () => (
  <Layout>
    <Feature
      previous="/features/lab"
      next="/features/academy"
      color={theme.palette.primary.main}
      title="Intrapreneuriat"
      icon={IntraIcon}
      subtitle="Transformer vos idées innovantes en business exploitable par l’entreprise."
      description="Les intrapreneurs sont clés dans votre organisation, ils sont moteurs dans la transformation de vos idées innovantes en de nouvelles activités économiques pour votre entreprise. Identifiez-les, mettez à leur disposition un espace digital dédié ainsi que des formations ciblées pour faciliter et structurer cette transformation."
      subSections={[
        'Structurer vos équipes intrapreneuriales',
        'Accélérer vos projets d’innovation',
        'Former et fidéliser vos intrapreneurs',
      ]}
      sections={[
        {
          title: 'Création des équipes',
          description:
            'Créez des équipes d’intrapreneurs facilement pour transformer vos idées en business exploitables pour l’entreprise.',
          image: B1,
        },
        {
          title: 'Formations digitales',
          description:
            'Les équipes bénéficient de coaching avec des objectifs, formations digitales et modèles de documents pour les guider.',
          image: B2,
        },
        {
          title: 'Messagerie & dépôt de fichiers',
          description:
            'L’équipe travaille efficacement grâce à une messagerie instantanée et un dépôt de fichiers dédiés sur chaque thématique.',
          image: B3,
        },
      ]}
    />
  </Layout>
);

export default IntraPage;
